<template>
  <div class="test">
    <header class="header">
      <div class="now">
        <div class="date">{{ +new Date() | dateNow }}</div>
        <div class="time">{{ nowTime }}</div>
        <div class="weather">
          <i class="img" :class="'qi-' + weatherObj.icon + '-fill'"></i>
          <span>{{ weatherObj.text }} {{ weatherObj.temp }}℃</span>
        </div>
      </div>
      <div class="logo">
        <!-- <img class="logo-img" :src="$imgOnlineUrl + '/bigWeb/head/DK-1.png'" /> -->
        <span class="logo-txt">丰景智慧农业平台</span>
      </div>

      <div class="title">
        <img
          class="other-txt"
          :src="$imgOnlineUrl + '/bigWeb/head/icon.png'"
          alt="后台界面"
          title="后台界面"
          @click="goReturn"
        />
        数字农业平台
      </div>
    </header>
    <div class="main animated fadeInUp">
      <div class="main-left">
        <!-- 虫情 -->
        <div class="equipment">
          <p class="title">虫情监测系统</p>
          <v-chart class="worm-chart" autoresize :option="wormOption"></v-chart>
        </div>
        <!-- 人员数据 -->
        <div class="personnel">
          <p class="title">人员数据</p>
          <div class="all-data">
            <span>农场总人数：88人</span>
            <span>管理人员：12人</span>
            <span>劳动人员：76人</span>
          </div>
          <div class="chart-personnel">
            <v-chart
              class="v-chart"
              autoresize
              :option="line_personnel"
            ></v-chart>
          </div>
        </div>
        <!-- 溯源动态 -->
        <div class="traceability">
          <p class="title">溯源动态</p>
          <div class="traceability-lists">
            <vue-seamless-scroll
              :data="traceabilityList"
              :class-option="defaultOption"
            >
              <div
                class="traceability-items"
                v-for="item in traceabilityList"
                :key="item.id"
              >
                <span class="name one-txt-cut" :title="item.title">{{
                  item.title
                }}</span>
                <span class="city one-txt-cut" :title="item.plot_title">{{
                  item.plot_title
                }}</span>
                <span class="time one-txt-cut" :title="item.created_time">{{
                  item.created_time
                }}</span>
                <span>{{ item.product_title }}</span>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="main-center">
        <!-- 其他信息 -->
        <div class="other">
          <div class="map" id="map"></div>
        </div>
        <!-- 实时监测数据 -->
        <div class="real-time-data">
          <p class="title now-data">实时监测数据</p>
          <div class="data-content">
            <div class="content-items weather-cont">
              <p class="contents-title">实时环境监测</p>
              <div
                class="contents-main-items weather-cont-items"
                v-for="item in chemicalList.qixiang"
                :key="item.id"
              >
                <div class="l">
                  <img
                    v-if="item.online == 1"
                    :src="
                      $imgOnlineUrl + '/bigWeb/realData/' + item.icon + '.png'
                    "
                  />
                  <img
                    v-else
                    :src="
                      $imgOnlineUrl +
                      '/bigWeb/realData/' +
                      item.icon +
                      '-offline.png'
                    "
                  />
                  <span>{{ item.title }}</span>
                </div>
                <div class="r">{{ item.last_data }}{{ item.data_unit }}</div>
              </div>
            </div>
            <div class="content-items weather-cont">
              <p class="contents-title">实时土壤监测</p>
              <div
                class="contents-main-items weather-cont-items"
                v-for="item in chemicalList.turang"
                :key="item.id"
              >
                <div class="l">
                  <img
                    v-if="item.online == 1"
                    :src="
                      $imgOnlineUrl + '/bigWeb/realData/' + item.icon + '.png'
                    "
                  />
                  <img
                    v-else
                    :src="
                      $imgOnlineUrl +
                      '/bigWeb/realData/' +
                      item.icon +
                      '-offline.png'
                    "
                  />
                  <span>{{ item.title }}</span>
                </div>
                <div class="r">{{ item.last_data }}{{ item.data_unit }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-right">
        <div class="monitor">
          <p class="title">视频监控</p>
          <div class="monitor-lists">
            <div
              class="monitor-items"
              v-for="item in chemicalList.jiankong"
              :key="item.id"
            >
              <iframe
                frameborder="0"
                name="video"
                scrolling="no"
                width="192"
                height="136"
                :src="`https://data.darsing.net/project/digital2/video/address?eid=${item.id}&return_type=iframe`"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="quotation">
          <p class="title">产品批次统计</p>
          <div class="chart-div">
            <v-chart
              class="v-chart"
              autoresize
              :option="option_column"
            ></v-chart>
          </div>
        </div>
      </div>
    </div>
    <div
      id="info-card"
      class="info-card win-box"
      :style="
        'background: url(' +
        $imgOnlineUrl +
        '/index/infoWin.png) center center no-repeat'
      "
    >
      <div class="title tops-box">
        <div class="title one-txt-cut">{{ infoWindowObj.title }}</div>
        <div class="status-box" :class="'active' + infoWindowObj.online">
          {{ infoWindowObj.online ? "在线" : "离线" }}
        </div>
      </div>
      <div class="bom-box">
        <p>
          {{ infoWindowObj.title }}&nbsp;&nbsp;设备状态：{{
            infoWindowObj.online ? "在线" : "离线"
          }}
        </p>
        <p>编号：{{ infoWindowObj.id }}</p>
      </div>
      <span class="top"></span>
    </div>
  </div>
</template>

<script>
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { use } from "echarts/core";
import { PieChart, BarChart, LineChart } from "echarts/charts";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

import Overlay from "ol/Overlay";
import "ol/ol.css";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import { hexToRGBA } from "@/utils/editColor";
import * as turf from "@turf/turf";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Collection from "ol/Collection";
import { Style, Fill, Stroke, Icon } from "ol/style";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import * as Interaction from "ol/interaction";
use([
  BarChart,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
]);

import moment from "moment";
import {
  getEquipmentGroupApi,
  getEquipmentListApi,
  getEquipmentInfoApi,
  getEquipmentChildListApi,
  getEquipmentCountApi,
  getWeatherApi,
  getBatchListApi,
  getAllMarkDataApi,
  getProductListApi,
} from "@/request/api";
import * as R from "ramda";
import { mapState } from "vuex";
export default {
  components: { VChart },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    return {
      // 天气
      overLayer: null,
      wormOption: {
        grid: {
          top: 55,
          left: 30,
          right: 30,
          bottom: 20,
        },

        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            interval: 0, //显示所有x轴标签
          },
        },
        yAxis: {
          name: "数量：只",
          nameGap: 30,
          nameLocation: "end",
          nameTextStyle: {
            color: "#fff",
            fontSize: 14,
            padding: [10, 0, 0, 60],
          },
          type: "value",
        },
        label: {
          show: true,
        },
        series: [
          {
            type: "bar",
            data: [],
            barMaxWidth: 16,
            itemStyle: {
              color: "#0C9E6E",
            },
          },
        ],
      },
      weatherObj: {},
      infoWindowObj: {
        title: "",
        status: "",
      },
      //设备总数
      stat: {},
      olMap: {}, // ol地图实例
      iconMarkLayer: null,
      vsource: new VectorSource({}),
      // 视频分页
      videoPageNum: 1,
      videoPageSize: 10,
      videoCount: 0,
      videoId: "",
      // 设备
      myPolygonSource: new VectorSource({
        wrapX: false,
        features: [],
      }),
      chemicalList: {
        turang: [],
        qixiang: [],
        jiankong: [],
      },
      // 定时器
      setInterval: null,
      setInterval2: null,
      end_day: undefined,
      day: undefined,
      openNum: true,
      // 溯源动态
      traceabilityList: [],
      // 实时时间
      nowTime: "",

      // 农产品 柱状图
      option_column: {
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          name: "批次",
          nameGap: 30,
          nameLocation: "end",
          nameTextStyle: {
            color: "#fff",
            fontSize: 14,
            align: "right",
          },
          type: "value",
        },
        series: [
          {
            type: "bar",
            data: [],
            barMaxWidth: 20,
          },
        ],
      },
      // 人员 折线图
      line_personnel: {
        xAxis: {
          type: "category",
          data: ["0", "4", "8", "12", "16", "20", "24"],
          boundaryGap: false,
          interval: 55,
        },
        yAxis: {
          name: "单位",
          nameLocation: "end",
          nameGap: 14,
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            align: "right",
          },

          type: "value",
          min: 0,
          max: 100,
          splitNumber: 6,
          interval: 21,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#36B77F",
          borderColor: "#36B77F",
          padding: [7, 12],
          formatter: "{b}号<br/> 出勤人数：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "line",
            data: [1, 54, 77, 24, 15, 87, 38],
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "rgb(54,183,127)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#36B77F",
              color: "#fff",
            },
            areaStyle: {
              color: "rgba(54,183,127,.28)",
            },
          },
        ],
      },

      centerVal: "",
      polygonsData: [],
      titleFeatures: [],
      allDataList: {},
      titleLayer: {},
      cameraList: [],
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      userInfo: (state) => state.user.userInfo,
      farmInfo: (state) => state.user.farmInfo,
    }),
    defaultOption() {
      return {
        step: 0.2,
        limitMoveNum: 2,
        hoverStop: true,
        direction: 1,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
  created() {
    this.getNowTime();
    this.allFunction();
    this.updatePage();
  },
  mounted() {
    this.getAllMarkDataApi();
    this.getProductList();
  },
  beforeDestroy() {
    // 卸载定时器
    if (this.setInterval) {
      clearInterval(this.setInterval);
    }
    this.setInterval = null;
    if (this.setInterval2) {
      clearInterval(this.setInterval2);
    }
    this.setInterval2 = null;
  },

  methods: {
    async getProductList() {
      this.option_column.xAxis.data = [];
      this.option_column.series[0].data = [];
      const { results } = await getProductListApi({
        manor_id: this.farmID,
        pn: 1,
        ps: 1000,
      });
      results.data.forEach((t) => {
        let obj = {
          name: t.title,
          value: t.batch_count || 0,
        };
        this.option_column.series[0].data.push(obj);
        this.option_column.xAxis.data.push(t.title);
      });
    },
    hoverPoint() {
      this.olMap.on("pointermove", (e) => {
        if (e.dragging) return;
        let feature = this.olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
          return feature;
        });
        if (feature) {
          let cityInfo = feature.get("citydata");
          let items = {};
          if (!cityInfo) return;

          switch (cityInfo.icon) {
            case "video":
              items = this.cameraList.find(
                (item) => item.group == "视频监控"
              ).items;
              break;
            case "grow":
              items = this.cameraList.find(
                (item) => item.group == "苗情监测"
              ).items;
              break;
            case "weather":
              items = this.cameraList.find(
                (item) => item.group == "气象采集"
              ).items;
              break;
            case "control":
              items = this.cameraList.find(
                (item) => item.group == "大棚控制"
              ).items;
              break;
            case "water":
              items = this.cameraList.find(
                (item) => item.group == "水质检测"
              ).items;
              break;
            case "soil":
              items = this.cameraList.find(
                (item) => item.group == "土壤检测"
              ).items;
              break;
            case "fertilizer":
              items = this.cameraList.find(
                (item) => item.group == "水肥一体"
              ).items;
              break;
            case "heat":
              items = this.cameraList.find(
                (item) => item.group == "加热机组"
              ).items;
              break;
            case "machine":
              items = this.cameraList.find(
                (item) => item.group == "农机监测"
              ).items;
              break;
            case "gate":
              items = this.cameraList.find(
                (item) => item.group == "阀门控制"
              ).items;
              break;
            case "insect":
              items = this.cameraList.find(
                (item) => item.group == "虫情监测"
              ).items;
              break;
            case "feed":
              items = this.cameraList.find(
                (item) => item.group == "智能投料"
              ).items;
              break;
          }
          this.infoWindowObj = items.find((item) => item.id == cityInfo.id);
          this.overLayer.setPosition(cityInfo.position);
        } else {
          this.overLayer.setPosition(undefined);
        }
        this.olMap.addOverlay(this.overLayer);
      });
    },
    pointStyle(online, iconType) {
      let imgUrl = iconType + online + ".png";
      let src = this.$imgOnlineUrl + "/index/" + imgUrl;
      let Styles = [];
      Styles.push(
        new Style({
          // 设置图标
          image: new Icon({
            src,
            anchor: [0.5, 0.5],
            scale: 1,
          }),
        })
      );
      return Styles;
    },
    cityPoint(list) {
      let features = [];
      let data = list;
      for (var i = 0; i < data.length; i++) {
        let feature = new Feature({
          geometry: new Point(data[i].position),
          type: "isPoint",
          citydata: data[i],
          title: data[i].title,
        });
        feature.setStyle(this.pointStyle(data[i].online, data[i].iconType));
        features.push(feature);
      }
      this.vsource.addFeatures(features);
    },
    initMap() {
      let position = this.farmInfo.position;
      position = position.split(",");
      this.iconMarkLayer = new VectorLayer({
        source: this.vsource,
      });
      this.olMap = new Map({
        target: "map",
        layers: [
          // 底图层
          new TileLayer({
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          // 星图地球注记层
          new TileLayer({
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          this.polygonLayer,
          this.iconMarkLayer,
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [+position[0], +position[1]],
          zoom: 16,
          maxZoom: 18,
          constrainResolution: true, // 设置缩放级别为整数
          smoothResolutionConstraint: false, // 关闭无级缩放地图
          extent: [73.66, 3.86, 135.05, 53.55], //中国边界
        }),
        interactions: new Interaction.defaults({
          doubleClickZoom: false, //屏蔽双击放大事件
        }),
      });
      const infoBox = document.getElementById("info-card");
      this.overLayer = new Overlay({
        element: infoBox,
        positioning: "center-center",
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      this.hoverPoint();
    },
    async getAllMarkDataApi() {
      const { code, results } = await getAllMarkDataApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.cameraList = results;

        results.forEach((item) => {
          if (item.group == "地块") {
            item.items.forEach((item2) => {
              // 处理颜色
              let color = hexToRGBA(item2.body_color, item2.alpha / 100);
              // 处理坐标
              if (item2.mark_type == 3 && item2.mark_data) {
                let polygonsItemObj = { coord: [] }; //多边形
                if (typeof item2.mark_data === "string") {
                  item2.mark_data = JSON.parse(item2.mark_data);
                }
                item2.mark_data.forEach((item3) => {
                  polygonsItemObj.coord.push([item3[0], item3[1]]);
                });
                polygonsItemObj.color = [color, item2.border_color];
                polygonsItemObj.name = `polygon${item2.id}`;
                polygonsItemObj.id = item2.id;
                polygonsItemObj.title = item.group;
                let polygon = turf.polygon([item2.mark_data]);
                this.centerVal = turf.centroid(polygon).geometry.coordinates;
                this.polygonsData.push(polygonsItemObj);
                // 标题
                let titleMarkerObj = {
                  text: item2.title,
                  geometry: new Point(this.centerVal),
                };
                this.titleFeatures.push(titleMarkerObj);
              }
            });
            this.$forceUpdate();
          } else {
            this.allDataList[item.group] = [];
            item.items.forEach((item2) => {
              let obj = {
                position: "",
                title: "",
                img: "",
              };
              let iconType = "";
              if (item2.icon === "video") {
                iconType = "video";
              } else if (item2.icon === "weather") {
                iconType = "weather";
              } else {
                iconType = "water";
              }

              obj.img = `${this.$imgOnlineUrl}/index/${iconType}${item2.online}.png`;
              obj.title = item.group;
              obj.id = item2.id;
              obj.online = item2.online;
              obj.iconType = iconType;
              obj.icon = item2.icon;
              obj.position = [
                +item2.mark_data.split(",")[0],
                +item2.mark_data.split(",")[1],
              ];
              this.allDataList[item.group].push(obj);

              this.cityPoint(this.allDataList[item.group]);
            });
          }
        });
        this.addTitles();
        this.showPolygon();
        setTimeout(() => {
          this.initMap();
        }, 80);
      }
    },
    featureStyle(x, y) {
      let style = new Style({
        fill: new Fill({
          color: x,
        }),
        stroke: new Stroke({
          width: 4,
          color: y,
        }),
      });
      return style;
    },
    showPolygon() {
      this.myPolygonSource.clear();
      let array = this.polygonsData;
      let features = [];
      for (let i = 0; i < array.length; i++) {
        let coord = array[i].coord;
        let x = array[i].color[0];
        let y = array[i].color[1];
        let z = array[i].name;
        let f = new Feature({
          geometry: new Polygon([coord]),
          type: "isPolygon",
          id: array[i].id,
          title: array[i].title,
        });
        f.setStyle(this.featureStyle(x, y));
        f.setProperties({
          name: z,
        });
        features.push(f);
      }
      this.myPolygonSource.addFeatures(features);
      this.polygonLayer = new VectorLayer({
        source: this.myPolygonSource,
        style: new Style({
          fill: new Fill({
            color: "",
          }),
          stroke: new Stroke({
            width: 4,
            color: "",
          }),
        }),
      });
    },
    createTitleStyle({ text }) {
      return new Style({
        text: new Text({
          font: "16px sans-serif",
          text,
          fill: new Fill({
            color: "#fff",
          }),
          backgroundFill: new Fill({
            color: "#032646",
          }),
          padding: [2, 2, 2, 2],
        }),
      });
    },
    addTitles() {
      this.titleLayer = new VectorLayer({
        source: new VectorSource({
          features: new Collection(
            this.titleFeatures.map((featureOptions) => {
              const feature = new Feature({
                geometry: featureOptions.geometry,
              });
              feature.setStyle(this.createTitleStyle(featureOptions));
              return feature;
            })
          ),
          format: new GeoJSON(),
        }),
      });
    },
    allFunction() {
      this.totalEquipmentCount();
      this.loadFullDataSets();
      this.getWeatherApi();
      this.getBatchListApi();
    },
    // //10分钟更新一次
    updatePage() {
      this.setInterval2 = setInterval(() => {
        this.allFunction();
      }, 600000);
    },

    goReturn() {
      this.$router.go(-1);
    },
    async getBatchListApi() {
      this.product_id = this.$route.query.id;
      const { code, results } = await getBatchListApi({
        manor_id: this.farmID,
        pn: 1,
      });
      if (code === 0) {
        this.traceabilityList = results.data;
      }
    },

    async getWeatherApi() {
      const { code, data } = await getWeatherApi({
        location: this.farmInfo.position,
      });
      if (code === 200) {
        this.weatherObj = data.now;
      }
    },
    // 获取时间
    getDateList(count, long) {
      let time = new Date().setMinutes(0);
      time = time = 24 * 60 * 60 * 1000;
      let categoryData = [];
      for (let i = 0; i <= count; i++) {
        categoryData.push(moment(time).format("HH:mm"));
        time += long;
      }
      return categoryData;
    },
    // 设备开关
    changeSwitch() {
      this.openNum = !this.openNum;
    },
    // 获取实时时间
    getNowTime() {
      let that = this;
      let theNowTime = function () {
        that.nowTime = that.timeNumber();
      };
      this.setInterval = setInterval(theNowTime, 1000);
    },
    timeNumber() {
      let today = new Date();
      let time =
        this.twoDigits(today.getHours()) +
        ":" +
        this.twoDigits(today.getMinutes());
      return time;
    },
    twoDigits(val) {
      if (val < 10) return "0" + val;
      return val;
    },

    async totalEquipmentCount() {
      const { code, data } = await getEquipmentCountApi({
        manor_id: this.farmID,
      });
      if (code === 200) {
        this.stat = data;
      }
    },
    async loadFullDataSets() {
      const { code, data } = await getEquipmentGroupApi();
      if (code === 200 && data) {
        for (const index in data) {
          this.loadSepecificDataSet(data[index]);
          if (data[index].icon == "insect") {
            let isId = [];

            const { results } = await getEquipmentListApi({
              manor_id: this.farmID,
              channel_id: data[index].id,
            });
            this.end_day = moment(new Date()).format("YYYY-MM-DD");
            this.day = moment().startOf("month").format("YYYY-MM-DD");
            isId = results.find((item) => {
              return item.alias == "insect-quantity";
            }).id;
            this.getEquipmentInfoApi_status(
              isId,
              data[index].icon,
              this.day,
              this.end_day
            );
          }
        }
      }
    },
    async getEquipmentInfoApi_status(
      table_id,
      icon,
      day = undefined,
      end_day = undefined
    ) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id,
        day,
        end_day,
        act: "status",
      });
      if (code === 0) {
        let item = {};
        switch (icon) {
          case "insect":
            for (let i = 0; i < results.data.length; i++) {
              item[results.data[i].name] =
                ~~item[results.data[i].name] + results.data[i].num;
            }
            for (let k = 0; k < Object.keys(item).length; k++) {
              let obj = {};
              obj.name = Object.keys(item)[k];
              obj.value = Object.values(item)[k];
              this.wormOption.series[0].data.push(obj);
              this.wormOption.xAxis.data.push(obj.name);
            }

            break;

          default:
            break;
        }

        this.$forceUpdate();
      }
    },
    async loadSepecificDataSet(data) {
      switch (data["name"]) {
        case "土壤检测":
          this.getChemicalList("turang", data["id"]);
          break;
        case "气象采集":
          this.getqixiang(data["id"]);
          break;
        case "视频监控":
          this.videoId = data["id"];
          this.getVideoList();
          break;
        default:
          break;
      }
    },
    async getqixiang(channel_id) {
      const { results } = await getEquipmentListApi({
        manor_id: this.farmID,
        channel_id,
      });
      this.getEquipmentChildListApi(results[0].id);
    },
    async getEquipmentChildListApi(table_id) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
        page_size: 20,
      });
      if (code === 0 && results) {
        this.chemicalList.qixiang = results;
        this.chemicalList.qixiang.pop();
        this.getqixiangEquipmentInfoApi_status(table_id);
      }
    },
    async getqixiangEquipmentInfoApi_status(table_id) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id,
        act: "status",
      });
      if (code === 0) {
        for (let key in this.chemicalList.qixiang) {
          let data1 = this.chemicalList.qixiang[key].number;
          for (let keys in results.data) {
            if (results.data[keys].number == data1) {
              this.chemicalList.qixiang[key].last_data = Number(
                results.data[keys].last_data
              );
              this.chemicalList.qixiang[key].icon = results.data[keys].alias;
            }
          }
        }
        this.chemicalList.qixiang = this.chemicalList.qixiang.splice(0, 9);
        this.$forceUpdate();
      }
    },
    async getVideoList(pageNum = 1) {
      const { code, results, count, page_num, page_size } =
        await getEquipmentListApi({
          manor_id: this.farmID,
          channel_id: this.videoId,
          page_num: pageNum,
        });
      if (code === 0 && Array.isArray(results)) {
        this.chemicalList["jiankong"] = results.slice(0, 6);

        this.videoCount = count;
        this.videoPageNum = page_num;
        this.videoPageSize = page_size;
        this.$forceUpdate();
      }
    },
    async getChemicalList(type, channel_id) {
      const { code, results } = await getEquipmentListApi({
        manor_id: this.farmID,
        channel_id,
      });
      if (code === 0 && Array.isArray(results)) {
        let chemicalBasics = results[0];
        const mergeObj = (a, b) => {
          return { ...a, ...b, last_data: b.last_data };
        };
        this.chemicalList[type] = R.zipWith(
          mergeObj,
          await this.getChemicalChildList(chemicalBasics.id),
          await this.getChemicalInfo(chemicalBasics.id)
        );
        if (type === "jiankong") {
          this.chemicalList["jiankong"] = results;
        }
        this.chemicalList[type].pop();
        // this.$notify.error({
        //   title: "错误",
        //   message: this.chemicalList[type].length,
        // });
        if (this.chemicalList[type].length > 9) {
          this.$forceUpdate();
          this.chemicalList[type] = this.chemicalList[type].splice(0, 9);
        }
      }
    },

    async getChemicalInfo(table_id) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id,
        act: "status",
      });
      if (code === 0 && results) {
        return results.data;
      }
    },

    async getChemicalChildList(table_id) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
      });
      if (code === 0 && results) {
        return results;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.test {
  min-height: 100vh;
  background: #051218;
  .header {
    width: 100%;
    height: 72px;
    padding: 0 35px;

    background: url(../../assets/image/bigWeb/head/top.png) center center
      no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .now {
      display: flex;
      align-items: center;
      .date {
        font-size: 21px;
        font-weight: 500;
        color: #acd1ff;
        margin-right: 47px;
      }
      .time {
        width: 100px;
        font-size: 36px;
        font-family: DS-Digital;
        font-weight: bold;
        color: #acd1ff;
        margin-right: 38px;
      }
      .weather {
        height: 23px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        border-left: 1px solid #3e90e5;
        .img {
          margin-right: 15px;
          color: #3e90e5;
          font-size: 36px;
        }
        span {
          font-size: 21px;
          font-weight: 500;
          color: #acd1ff;
        }
      }
    }
    .logo {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // .logo-img {
      //   width: 56px;
      //   height: 26px;
      //   margin-right: 20px;
      // }
      .logo-txt {
        font-size: 32px;
        font-weight: bold;
        color: #fff;
      }
    }

    .title {
      font-size: 21px;
      font-weight: 500;
      color: #acd1ff;
      display: flex;
      align-items: center;
      .other-txt {
        width: 27px;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
  .main {
    // padding: 0 21px;
    margin: 30px 0 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .equipment,
    .traceability,
    .monitor,
    .quotation,
    .personnel,
    .real-time-data {
      width: 425px;
      width: 424px;
      height: 303px;
      background: url("../../assets/image/bigWeb/equipment/backdrop.png") center
        center no-repeat;
      background-size: cover;
      position: relative;
      padding: 54px 8px 13px;
      margin-bottom: 25px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: transparent;
        background: linear-gradient(0deg, #03b8de 0%, #0085ff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: absolute;
        top: 9px;
        left: 51px;
        &.now-data {
          top: 12px;
          left: 61px;
        }
      }
    }
    .traceability {
      .traceability-lists {
        height: 100%;
        width: 100%;
        overflow: hidden;
        .traceability-items {
          width: 100%;
          height: 39px;
          background: rgba(14, 50, 72, 0.3);
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2px;
          cursor: pointer;
          &:nth-of-type(2n) {
            background: #051218;
          }
          &:last-of-type {
            margin: 0;
          }
          span {
            color: #fff;
            font-size: 12px;
            &.name {
              width: 36px;
            }
            &.city {
              width: 72px;
            }
            &.time {
              width: 114px;
            }
          }
        }
      }
    }
    .monitor {
      width: 426px;
      height: 548px;
      background: url("../../assets/image/bigWeb/monitor/backdrop.png") center
        center no-repeat;
      .monitor-lists {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        // overflow-y: auto;
        // &::-webkit-scrollbar {
        //   width: 7px;
        // }
        // &::-webkit-scrollbar-thumb {
        //   border-radius: 10px;
        //   background-color: #3e90e5;
        // }
        .monitor-items {
          width: 192px;
          height: 136px;
          margin: 0 12px 26px 0;
          overflow: hidden;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      }
    }
    .other {
      width: 984px;
      height: 631px;
      margin-bottom: 25px;
      .map {
        width: 100%;
        height: 100%;
      }
    }
    .real-time-data {
      width: 985px;
      background: url("../../assets/image/bigWeb/realData/backdrop.png") center
        center no-repeat;
      padding: 60px 19px 13px;
      .data-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .content-items {
          flex: 2;
          .contents-title {
            font-size: 14px;
            color: #fff;
            font-weight: bold;
            position: relative;
            padding-left: 13px;
            margin: 0 28px 13px 0;
            &::before {
              content: "";
              width: 2px;
              height: 12px;
              background: #3e90e5;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .contents-main {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .contents-main-items {
              width: 226px;
              height: 45px;
              background: #0e3248;
              margin: 0 9px 7px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;
              padding: 0 13px;
              &:nth-of-type(2n) {
                margin: 0;
              }
              div.l {
                display: flex;
                align-items: center;
                img {
                  width: 32px;
                  height: 32px;
                  margin-right: 10px;
                }
                font-size: 12px;
              }
              div.r {
                font-size: 16px;
                font-weight: 500;
                &.active {
                  color: #f2423e;
                }
              }
            }
          }
          &.weather-cont {
            display: flex;
            flex-wrap: wrap;
            .contents-title {
              width: 226px;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              color: #fff;
              font-weight: bold;
              position: relative;
              margin: 0 9px 7px 0;
              padding: 0 13px;

              &::before {
                content: "";
                width: 2px;
                height: 12px;
                background: #3e90e5;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .contents-main-items.weather-cont-items {
              width: 226px;
              height: 40px;
              background: #0e3248;
              margin: 0 9px 7px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;
              padding: 0 13px;
              // &:nth-of-type(2n) {
              //   margin: 0;
              // }
              div.l {
                display: flex;
                align-items: center;
                img {
                  width: 32px;
                  height: 32px;
                  margin-right: 10px;
                }
                font-size: 12px;
              }
              div.r {
                font-size: 16px;
                font-weight: 500;
                &.active {
                  color: #f2423e;
                }
              }
            }
          }
        }
      }
    }
    .quotation {
      width: 424px;
      height: 385px;
      background: url("../../assets/image/bigWeb/quotation/backdrop.png") center
        center no-repeat;
      .chart-div {
        width: 100%;
        height: 100%;

        .v-chart {
          width: 100%;
          height: 100%;
        }
      }
    }
    .personnel {
      width: 426px;
      height: 305px;
      .all-data {
        width: 100%;
        display: flex;
        justify-content: space-around;
        color: #fff;
        font-size: 12px;
      }
      .chart-personnel {
        width: 100%;
        height: 113%;

        .v-chart {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .win-box {
    .tops-box {
      width: 100%;
      height: 51px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding: 0 34px;
      display: flex;
      align-items: center;

      .title {
        color: #3e90e5;
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        margin-right: 10px;
      }

      .status-box {
        width: 45px;
        height: 17px;
        border-radius: 17px;
        border: 1px solid #08a600;
        line-height: 14px;
        text-align: center;
        font-size: 11px;
        color: #08a600;
        &.active0 {
          color: red !important;
          border-color: red !important;
        }
      }
    }

    .bom-box {
      width: 100%;
      height: calc(100% - 51px);
      padding: 20px 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p {
        font-size: 14px;
        color: #fff;
        margin-bottom: 19px;
      }
    }
  }
  .info-card {
    width: 320px;
    height: 144px;
    position: absolute;
    bottom: 18px;
    left: -160px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0);

    .title {
      height: 40px;
      color: #ffffff;

      span.title_name {
        position: relative;
        top: 7px;
        left: 10px;
        font-size: 18px;
      }
    }

    span.top {
      width: 0;
      height: 0;
      font-size: 0;
      overflow: hidden;
      position: absolute;
      border-width: 10px;
      border-style: solid dashed dashed;
      border-color: #051627 transparent transparent;
      transform: translateX(-50%);
      left: 50%;
      bottom: -13px;
    }
  }
}
</style>
